<template>
  <div>
    <h3 class="text-lg font-bold mb-4 font-heading">Your Details</h3>
    <div class="text-red-400 mb-4" v-if="this.order.id">User is already an existing learner, personal information not editable</div>
    <div class="w-full flex flex-wrap border-b mb-6">
      <div class="w-full md:w-1/3 pr-8">
        <div class="flex-grow mr-1 mb-6">
          <label for="first_name" class="block text-xs mb-2">First Name<span class="text-propqual-orange">*</span></label>
          <input type="text" id="first_name" v-model="order.first_name" class="input w-full p-2 border" :class="registrantExisted?'bg-gray-200':'bg-white'" tabindex="1" :disabled="registrantExisted">
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('first_name')" v-text="errorBag.get('first_name')"></small>
        </div>
        <div class="flex-grow mr-1 mb-6">
          <label for="last_name" class="block text-xs mb-2">Last Name:<span class="text-propqual-orange">*</span></label>
          <input type="text" id="last_name" v-model="order.last_name" class="input w-full p-2 border" :class="registrantExisted?'bg-gray-200':'bg-white'" tabindex="2" :disabled="registrantExisted">
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('last_name')" v-text="errorBag.get('last_name')"></small>
        </div>
        <div class="flex-grow mr-1 mb-6">
          <label for="email" class="block text-xs mb-2">Email:<span class="text-propqual-orange">*</span></label>
          <input type="text" id="email" v-model="order.email" class="input w-full p-2 border" :class="registrantExisted?'bg-gray-200':'bg-white'" tabindex="3" :disabled="registrantExisted">
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('email')" v-text="errorBag.get('email')"></small>
        </div>
      </div>
      <div class="w-full md:w-1/3 pr-8">
        <div class="flex-grow mr-1 mb-6">
          <label for="date_of_birth" class="block text-xs mb-2">Date of Birth:<span class="text-propqual-orange">*</span></label>
          <Datepicker id="date_of_birth" v-model="selectedDate" class="input w-full border" :class="registrantExisted?'bg-gray-200':'bg-white'" tabindex="4" placeholder="DD/MM/YYYY"
            :format="dateFormat" textInput autoApply :startDate="startDate" :flow="calendarFlow" :enableTimePicker="enableTimePicker" :disabled="registrantExisted"></Datepicker>
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('date_of_birth')" v-text="errorBag.get('date_of_birth')"></small>
        </div>
        <div class="flex-grow mr-1 mb-6">
          <div class="block text-xs mb-2">Gender:<span class="text-propqual-orange">*</span></div>
          <div class="block mb-2 pt-2">
            <div class="inline-block pr-2"><input type="radio" id="gender-male" v-model="order.gender" value="Male" class="align-middle cursor-pointer" :disabled="registrantExisted"></div>
            <label for="gender-male" class="text-sm align-middle pb-4 pr-8 cursor-pointer">Male</label>
            <div class="inline-block pr-2"><input type="radio" id="gender-female" v-model="order.gender" value="Female" class="align-middle cursor-pointer" :disabled="registrantExisted"></div>
            <label for="gender-female" class="text-sm align-middle pb-4 cursor-pointer">Female</label>
          </div>
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('gender')" v-text="errorBag.get('gender')"></small>
        </div>
      </div>
      <div class="w-full md:w-1/3">
        <div class="flex-grow mr-1 mb-6">
          <address-lookup
          v-if="addressLookupOpen"
          v-model="address"
          @leave="addressLookupOpen = false"
          name-number-key="line1"
          street-key="line2"
          line3-key="line3"
          town-key="town"
          county-key="county"
          postcode-key="postcode"
          :token="token"
          :disabled="registrantExisted"
          />
          <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('postcode')" v-text="errorBag.get('postcode')"></small>
        </div>
      </div>
    </div>
    <h3 class="text-lg font-bold mb-4 font-heading">Confirm Exam Registration for:</h3>
    <div v-if="coursePrePopulated">
      <p>{{ order.course_name }}</p>
    </div>
    <div v-else>
      <div v-for="(item, i) in courses" :key="i" class="w-full block mb-2">
        <div class="inline-block pr-2"><input type="radio" :id="'course-' + item.id" class="align-middle cursor-pointer" v-model="order.course_id" :value="item.id" /></div>
        <label :for="'course-' + item.id" class="text-sm align-middle pb-4 cursor-pointer">{{ item.name }}</label>
      </div>
    </div>
    <small class="block text-propqual-orange text-medium mt-2" v-if="errorBag.get('course_id')" v-text="errorBag.get('course_id')"></small>
  </div>
</template>

<script>
import AddressLookup from '@/components/AddressLookup.vue';
import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { AddressLookup, Datepicker },
  props: {
    modelValue: Object,
    courses: Array,
    coursePrePopulated: Boolean,
    token: String,
    errorBag: Object,
    registrantExisted: Boolean
  },
  data() {
    return {
      addressLookupOpen: true,
      order: this.modelValue,
      address: {
        'line1': null,
        'line2': null,
        'line3': null,
        'town': null,
        'county': null,
        'postcode': null
      },
      selectedDate: null,
      startDate: new Date(2000, 0, 1),
      dateFormat: 'dd/MM/yyyy',
      enableTimePicker: false,
      calendarFlow: ['month', 'year', 'calendar']
    }
  },
  watch: {
    order: {
      handler() {
        this.$emit('update:modelValue', this.order);
      },
      deep: true
    },
    address: {
      handler() {
        this.order.address_line1 = this.address.line1;
        this.order.address_line2 = this.address.line2;
        this.order.address_line3 = this.address.line3;
        this.order.address_town = this.address.town;
        this.order.address_county = this.address.county;
        this.order.address_postcode = this.address.postcode;
      },
      deep: true
    },
    selectedDate: {
      handler() {
        this.order.date_of_birth = moment(this.selectedDate).format('DD/MM/YYYY');
      }
    }
  },
  created () {
    this.address = {
        'line1': this.order.address_line1 ? this.order.address_line1 : null,
        'line2': this.order.address_line2 ? this.order.address_line2 : null,
        'line3': this.order.address_line3 ? this.order.address_line3 : null,
        'town': this.order.address_town ? this.order.address_town : null,
        'county': this.order.address_county ? this.order.address_county : null,
        'postcode': this.order.address_postcode ? this.order.address_postcode : null
    };
    if (this.order.date_of_birth) {
      this.selectedDate = moment(this.order.date_of_birth, 'DD/MM/YYYY');
    }
  },
}
</script>

<style>
  .dp__theme_light {
    --dp-primary-color: #ff6137;
  }
  .dp__theme_light .dp__input {
    border: none;
    font-size: 100%;
    line-height: inherit;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
</style>
