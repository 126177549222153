import { createRouter, createWebHistory } from 'vue-router'
import ExamRegistration from '../views/ExamRegistration.vue'
// import HoldingPage from '../views/HoldingPage.vue'
import RegistrationSuccess from '../views/RegistrationSuccess.vue'
import RegistrationCancelled from '../views/RegistrationCancelled.vue'

const routes = [
  {
    path: '/',
    name: 'exam-registration',
    component: ExamRegistration
  },
  {
    path: '/success',
    name: 'registration-success',
    component: RegistrationSuccess
  },
  {
    path: '/cancel',
    name: 'registration-cancelled',
    component: RegistrationCancelled
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
