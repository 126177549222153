<template>
  <div class="home font-body text-gray-700">
    <header-component />
    <loader-component v-if="!loaded" class="mb-6" />

    <div v-else class="rounded-lg bg-white relative w-full py-8 px-4">
      <div class="container max-w-screen-md mx-auto text-center">
        <div class="flex w-full h-12 mb-8">
          <div class="w-full md:w-1/2 pr-6 border-r">
            <img src="@/assets/logo.png" class="h-full float-right" />
          </div>
          <div class="w-full md:w-1/2 pl-6">
            <img src="@/assets/images/logo-abbe.png" class="h-full" />
          </div>
        </div>
        <h3 class="flex justify-center items-center font-bold font-heading text-xl mb-1 leading-none">ABBE Registration</h3>
        <img src="@/assets/icons/tick_circle.svg" class="mx-auto mb-1 w-32 h-32" />
        <h3 class="text-center font-bold font-heading text-xl mb-4">Thank you for your Payment</h3>
        <div class="border-b border-grey-light mb-6">
          <p class="leading-relaxed text-gray-600">Your registration has been processed, payment taken, and your registration details sent to ABBE.</p>
          <p class="leading-relaxed text-gray-600 mb-6">You should expect to receive confirmation of your registration along with your 'Unique Learner Number'
            within 14 days.</p>
        </div>
        <h4 class="font-bold font-heading text-lg text-center mb-1">PropQual - ABBE Registration</h4>
        <h5 class="text-lg text-center mb-4">x1 User</h5>
        <table class="w-full mb-8">
          <tbody>
            <tr>
              <td class="text-left pb-6">
                <p class="text-sm">{{ order.course_name }}</p>
              </td>
              <td class="align-bottom text-right text-sm pb-6">
                {{ '£' + parseFloat(order.subtotal).toFixed(2) }}
              </td>
            </tr>
            <tr class="border-t border-grey-light text-right">
              <td colspan="2" class="text-sm pt-6 pb-2">{{ 'VAT £' + parseFloat(order.vat).toFixed(2) }}</td>
            </tr>
            <tr class="border-b border-grey-light text-right">
              <td colspan="2" class="pb-6 text-xl font-bold">{{ '£' + parseFloat(order.total).toFixed(2) }}</td>
            </tr>
            </tbody>
        </table>
        <table class="w-full mb-6">
          <tbody>
            <tr>
              <td class="w-1/2 text-right pr-4">Reference: ABBE Registration</td>
              <td class="w-1/2 text-left pl-4">{{ 'Order No: ' + order.id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import api from "@/api";
import LoaderComponent from '@/components/LoaderComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'RegistrationSuccessView',
  components: {
    LoaderComponent,
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      loaded: false,
      sessionId: null,
      order: null
    }
  },
  created () {
    this.sessionId = this.$route.query.session_id
    Promise.all([
      this.loadOrder()
    ]).then(() => {
      this.loaded = true
    })
  },
  methods: {
    loadOrder () {
      return api.post('orders/payment-successful', {
        session_id: this.sessionId
      }).then((response) => {
        this.order = response.data
      })
    }
  }
}
</script>
