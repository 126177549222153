import axios from 'axios'

const api = axios.create({
    baseURL: `${process.env.VUE_APP_ROOT_API}/propqual`,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': `Bearer ${process.env.VUE_APP_ROOT_API_TOKEN}`,
    }
})

export { api as default }
