export default {
  setInitialized (state) {
    state.initialized = true
  },

  setErrorMessage (state, message) {
    state.errorMessage = message
  },

  setSuccessMessage (state, message) {
    state.successMessage = message
  },

  setErrorCode (state, code) {
    state.errorCode = code
  },
}
