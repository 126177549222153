<template>
    <footer class="py-6 bg-propqual-blue-dark">
        <div class="container text-center text-sm text-white">
            Copyright &copy; {{ year }} PropQual. All Rights Reserved.
        </div>
    </footer>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    year () {
      return moment().format('YYYY')
    }
  }
}
</script>
