<template>
  <div class="container max-w-screen-lg px-6 mb-12">
    <h3 class="w-full text-lg mb-6 font-bold font-heading">ABBE Registration <span class="text-sm">(through PropQual)</span></h3>

    <loader-component v-if="!loaded || submittingOrder" />

    <div v-else-if="!hasAccess" class="text-sm">
      <p>Please first complete the course at <a href="https://www.propqual.co.uk/" class="text-propqual-orange">https://www.propqual.co.uk/</a>.</p>
    </div>

    <div v-else class="flex flex-wrap">
      <div class="mb-6 w-full">
        <div>

          <!-- STAGE TABS -->
          <div class="w-full flex text-sm">
            <div @click="stage = i" class="flex flex-col w-1/4 border-l" :class="[{'border-r': i === 1}, {'border-b': i !== stage}, {'border-t': i !== stage}]" v-for="(stageName, i) in stages" :key="i">
              <div class="w-full h-full px-4 py-4 border-t-4" :class="[(i === stage ? 'border-t-4 border-propqual-orange' : 'border-transparent bg-neutral-100'), (i === stage ? 'cursor-default' : 'cursor-pointer')]">
                <div class="flex h-full items-center"><div class="w-8"><span class="w-6 h-6 rounded-full flex flex-col items-center justify-center text-white" :class="i === stage ? 'bg-propqual-orange' : 'bg-neutral-400'">{{ i + 1 }}</span></div> <div>{{ stageName }}</div></div>
              </div>
            </div>
            <div class="flex flex-col w-1/2 border-b"></div>
          </div>

          <div class="w-full text-sm mb-2 p-6 border-x border-b">

            <div class="bg-yellow-200 flex p-4 mb-6 rounded text-sm leading-normal" v-if="!isEmpty(errorBag.errors)">
              <div class="flex-grow">
                <strong class="block mb-3 px-2 text-lg">There were problems with your registration. Please resolve these issues and try again.</strong>
                <ul class="list-disc pl-6 pb-2">
                  <li v-for="(error, i) in errorBag.errors" :key="i">{{ error.join(' ') }}</li>
                </ul>
              </div>
              <span class="text-base leading-none ml-3 w-6 h-6 rounded-full flex flex-col items-center justify-center font-bold bg-gray-600 hover:bg-propqual-orange cursor-pointer text-white" @click="errorBag.clear()">X</span>
            </div>

            <!-- STAGE FORM -->
            <component :is="stageView" v-model="order" :courses="courses" :coursePrePopulated="coursePrePopulated" :token="token" :errorBag="errorBag" :registrantExisted="registrantExisted" @submit="submitOrder" />

            <div class="w-full text-right" v-if="stage < 1">
              <button type="button" class="bg-primary py-3 px-20 text-sm border border-propqual-orange bg-propqual-orange-lightest hover:bg-propqual-orange text-propqual-orange hover:text-white focus:outline-none" @click="stage++">
                Next Step &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import ErrorBag from "@/ErrorBag";
import LoaderComponent from '@/components/LoaderComponent.vue'
import RegistrationStage0 from './stages/RegistrationStage0.vue'
import RegistrationStage1 from './stages/RegistrationStage1.vue'
import { find, isEmpty } from 'lodash'

export default {
  components: {
    LoaderComponent,
    RegistrationStage0,
    RegistrationStage1
  },
  data () {
    return {
      stage: 0,
      stages: [
        'Your Details',
        'Review & Payment'
      ],
      courses: null,
      order: {
        id: null,
        propqual_user_id: null,
        first_name: null,
        last_name: null,
        email: null,
        date_of_birth: null,
        gender: null,
        address_line1: null,
        address_line2: null,
        address_line3: null,
        address_town: null,
        address_county: null,
        address_postcode: null,
        address_country: 'GB',
        postcode: null,
        course_id: null,
        course_name: null,
        subtotal: 0,
        vat: 0,
        total: 0
      },
      orderResponse: null,
      errorBag: new ErrorBag(),
      coursePrePopulated: false,
      hasAccess: false,
      loaded: false,
      token: null,
      submittingOrder: false,
      registrantExisted: false
    }
  },
  computed: {
    stageView () {
      return `registration-stage${this.stage}`
    }
  },
  watch: {
    'order.course_id': {
      handler (id) {
        let selectedCourse = find(this.courses, (course) => course.id === id);
        this.order.course_name = selectedCourse.name;
        this.order.subtotal = parseFloat(selectedCourse.exam_fee).toFixed(2);
        this.order.vat = parseFloat(selectedCourse.exam_fee * 0.2).toFixed(2);
        this.order.total = parseFloat(selectedCourse.exam_fee * 1.2).toFixed(2);
      },
      deep: true
    }
  },
  created () {
    this.init();
  },
  methods: {
    isEmpty,
    async init() {
      return api.post('init', {
        id: this.$route.query.user,
        ref: this.$route.query.ref
      })
      .then(
        (response) => {
          this.hasAccess = response.data.access;
          if (this.hasAccess) {
            if(response.data.existed_record!==false){
              this.order.id = response.data.id;
              this.registrantExisted = true
              this.order.first_name = response.data.existed_record.first_name
              this.order.last_name = response.data.existed_record.last_name
              this.order.email = response.data.existed_record.email
              this.order.gender = response.data.existed_record.gender
              this.order.date_of_birth = response.data.existed_record.date_of_birth
              this.order.address_line1 = response.data.existed_record.address_line1
              this.order.address_line2 = response.data.existed_record.address_line2
              this.order.address_line3 = response.data.existed_record.address_line3
              this.order.address_town = response.data.existed_record.address_town
              this.order.address_county = response.data.existed_record.address_county
              this.order.address_postcode = response.data.existed_record.address_postcode
              this.order.address_country = response.data.existed_record.address_country
              this.order.postcode = response.data.existed_record.postcode
            }
            this.token = response.data.token;
            this.order.propqual_user_id = parseInt(this.$route.query.user);
            this.loadCourses();
          } else {
            this.loaded = true;
          }
        },
        () => {
          this.loaded = true;
        }
      );
    },
    async loadCourses() {
      return api.get('courses', {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        }
      })
      .then((response) => {
        this.courses = response.data.courses;
        if (this.$route.query.course) {
          this.coursePrePopulated = true;
          let id = this.$route.query.course;
          let selectedCourse = find(this.courses, (course) => course.propqual_id === id);
          this.order.course_id = selectedCourse.id;
        }
        this.loaded = true;
      });
    },
    submitOrder () {
      this.errorBag.clear()
      this.submittingOrder = true

      api.post('orders', this.order, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        }
      }).then((response) => {
        this.orderResponse = response.data
        if (response.data.checkout_url) {
          window.location = response.data.checkout_url
        } else {
          this.submittingOrder = false
        }

      }, (errors) => {
        this.stage = 0
        this.submittingOrder = false
        this.errorBag.store(errors.response.data)
      })
    }
  }
}
</script>
