<template>
  <nav class="bg-white border-b border-gray-300 px-px py-4 mb-6">
    <div class="px-6">
      <div class="flex md:items-center flex-col md:flex-row">
        <div class="flex md:block md:flex-shrink">
          <a href="https://www.propqual.co.uk/" class="block flex-grow">
            <img src="@/assets/logo.png" alt="PropQual" class="max-h-14" />
          </a>
        </div>

        <div class="flex-grow text-neutral-700 mt-0 pl-12 text-base uppercase font-navigation">
          <a href="https://www.propqual.co.uk/my/">Dashboard</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {}
</script>
