<template>
  <div>
    <div class="w-full flex flex-wrap mb-4">
      <div class="w-full md:w-1/2 pr-4">
        <h3 class="text-lg leading-6 font-bold mb-6 font-heading">ABBE Registration Details</h3>
        <table class="w-full">
          <tbody class="align-top">
            <tr>
              <td class="pb-2">Name:</td>
              <td class="pb-2 font-bold">{{ registrantName }}</td>
            </tr>
            <tr>
              <td class="pb-2">DOB:</td>
              <td class="pb-2 font-bold">{{ form.date_of_birth }}</td>
            </tr>
            <tr>
              <td class="pb-2">Gender:</td>
              <td class="pb-2 font-bold">{{ form.gender }}</td>
            </tr>
            <tr>
              <td class="pb-2">Course:</td>
              <td class="pb-2 font-bold">{{ form.course_name }}</td>
            </tr>
            <tr>
              <td class="pb-2">Address:</td>
              <td class="pb-2 font-bold">{{ addressLine }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full md:w-1/2 pl-4">
        <h4 class="leading-6 font-bold text-base mb-6">Your Order Summary</h4>
        <h4 class="block text-sm pb-3">Total</h4>
        <table class="w-full mb-6">
          <tbody>
            <tr>
              <td class="align-top text-sm pb-2">
                Subtotal
              </td>
              <td class="align-top text-right text-sm pb-2">{{ '£' + form.subtotal }}</td>
            </tr>
            <tr>
              <td class="align-top text-sm pb-3">
                VAT (20%)
              </td>
              <td class="align-top text-right text-sm pb-3">{{ '£' + form.vat }}</td>
            </tr>
            <tr>
              <td class="align-top text-sm font-bold">
                Total
              </td>
              <td class="align-top text-right text-sm font-bold">{{ '£' + form.total }}</td>
            </tr>
          </tbody>
        </table>
        <div class="border-t pt-6">
          <p class="text-xs leading-normal pb-8">Before you place your order, please check the details on this page are correct, as this
            is the information we will sent to ABBE regarding the registration onto their platform</p>
          <button class="w-full py-5 text-white text-xs uppercase bg-propqual-orange hover:bg-propqual-orange-dark" @click="$emit('submit')">Make Payment</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
    courses: Array
  },
  data() {
    return {
      form: this.modelValue
    }
  },
  computed: {
    registrantName () {
      return this.form.first_name && this.form.last_name
        ? this.form.first_name + ' ' + this.form.last_name
        : ''
    },
    addressLine () {
      if (! this.form.address_line1) {
        return ''
      }
      let address = this.form.address_line1
      address += this.form.address_line2 ? ', ' + this.form.address_line2 : ''
      address += this.form.address_line3 ? ', ' + this.form.address_line3 : ''
      address += this.form.address_town ? ', ' + this.form.address_town : ''
      address += this.form.address_county ? ', ' + this.form.address_county : ''
      address += this.form.address_postcode ? ', ' + this.form.address_postcode : ''
      return address
    }
  }
}
</script>
