<template>
  <div class="flex flex-wrap mb-6">
    <label for="postcode" class="block text-xs mb-2">Home Postcode:<span class="text-propqual-orange">*</span></label>
    <div class="w-full flex mb-3">
      <div class="w-full md:w-1/2 pr-4">
        <input type="text" id="postcode" v-model="address[postcodeKey]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" maxlength="8" tabindex="4" :disabled="disabled">
      </div>
      <div class="w-full md:w-1/2" v-if="!disabled">
        <button type="button" class="w-full bg-primary py-2 text-sm border border-propqual-orange bg-propqual-orange-lightest hover:bg-propqual-orange text-propqual-orange hover:text-white focus:outline-none" @click="search">
          Find Address
        </button>
      </div>
    </div>
    <loader-component v-if="searching" />
    <div v-else-if="suggestions.length > 0 && !manualOpen" class="w-full">
      <div class="w-full flex mb-3">
        <div class="w-full bg-white p-2 border">
          <select v-model="selectedSuggestion" class="w-full" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled">
          <option :value="null">Select Address</option>
          <option v-for="(suggestion, i) in suggestions" :key="i" :value="suggestion.data">
            {{ suggestion.formatted }}
          </option>
          </select>
        </div>
      </div>
      <div class="text-xs text-propqual-orange underline cursor-pointer" @click="manualOpen = true">Enter address manually</div>
    </div>
    <div v-else-if="manualOpen||disabled" class="w-full">
      <div class="flex-grow mb-3">
        <label for="address_line1" class="block text-xs mb-2">Address Line 1:<span class="text-propqual-orange">*</span></label>
        <input type="text" id="address_line1" v-model="address[nameNumberKey]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled" />
      </div>
      <div class="flex-grow mb-3">
        <label for="address_line2" class="block text-xs mb-2">Address Line 2:</label>
        <input type="text" id="address_line2" v-model="address[streetKey]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled" />
      </div>
      <div class="flex-grow mb-3">
        <label for="address_line3" class="block text-xs mb-2">Address Line 3:</label>
        <input type="text" id="address_line3" v-model="address[line3Key]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled" />
      </div>
      <div class="flex-grow mb-3">
        <label for="address_town" class="block text-xs mb-2">Town:<span class="text-propqual-orange">*</span></label>
        <input type="text" id="address_town" v-model="address[townKey]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled" />
      </div>
      <div class="flex-grow mb-3">
        <label for="address_county" class="block text-xs mb-2">County:<span class="text-propqual-orange">*</span></label>
        <input type="text" id="address_county" v-model="address[countyKey]" class="input w-full p-2 border" :class="disabled?'bg-gray-200':'bg-white'" :disabled="disabled" />
      </div>
    </div>
    <div v-else class="text-xs text-propqual-orange underline cursor-pointer" @click="manualOpen = true">Enter address manually</div>
  </div>
</template>

<script>
import api from '@/api'
import debounce from 'lodash/debounce'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  components: {
    LoaderComponent
  },
  props: {
    modelValue: Object,
    token: String,
    nameKey: {
      type: String,
      default: null
    },
    nameNumberKey: {
      type: String,
      default: 'line1'
    },
    streetKey: {
      type: String,
      default: 'line2'
    },
    line3Key: {
      type: String,
      default: 'line3'
    },
    townKey: {
      type: String,
      default: 'town'
    },
    countyKey: {
      type: String,
      default: 'county'
    },
    postcodeKey: {
      type: String,
      default: 'postcode'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      address: this.modelValue,
      suggestions: [],
      selectedSuggestion: null,
      manualOpen: false,
      searching: false
    }
  },
  watch: {
    selectedSuggestion (suggestion) {
      if (!suggestion) {
        return
      }

      this.address[this.nameNumberKey] = suggestion.address_name_number
      this.address[this.streetKey] = suggestion.address_line2
      this.address[this.line3Key] = suggestion.address_line3
      this.address[this.townKey] = suggestion.address_town
      this.address[this.countyKey] = suggestion.address_line4
      this.address[this.postcodeKey] = suggestion.address_postcode
    }
  },
  methods: {
    search: debounce(function () {
      if (!RegExp('^[A-PR-UWYZ][A-HJ-Y]?[0-9][0-9A-HJKMNPR-Y]? ?[0-9][ABD-HJLNP-UW-Z]{2}$', 'gi').test(this.address[this.postcodeKey])) {
        return
      }

      this.selectedSuggestion = null
      this.searching = true
      api.get('address-lookup', {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        },
        params: {
          postcode: this.address[this.postcodeKey]
        }
      }).then((response) => {
        this.suggestions = response.data
        this.searching = false
        this.manualOpen = false
      }, () => {
        this.searching = false
        this.manualOpen = true
      })
    }, 500)
  }
}
</script>
