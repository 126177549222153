<template>
  <div class="home font-body text-gray-700">
    <header-component />
    <loader-component v-if="!loaded" class="mb-6" />

    <div v-else class="rounded-lg bg-white relative w-full py-8 px-4">
      <div class="container max-w-screen-md mx-auto text-center">
        <div class="flex w-full h-12 mb-8">
          <div class="w-full md:w-1/2 pr-6 border-r">
            <img src="@/assets/logo.png" class="h-full float-right" />
          </div>
          <div class="w-full md:w-1/2 pl-6">
            <img src="@/assets/images/logo-abbe.png" class="h-full" />
          </div>
        </div>
        <h3 class="flex justify-center items-center font-bold font-heading text-xl mb-1 leading-none">ABBE Registration</h3>
        <img src="@/assets/icons/attention_circle.svg" class="mx-auto mb-1 w-32 h-32" />
        <h3 class="text-center font-bold font-heading text-xl mb-4">Order Cancelled</h3>
        <p class="leading-relaxed text-gray-600 mb-6">Your registration has been cancelled.</p>
        <p class="mb-12"><router-link to="/" class="text-propqual-orange no-underline hover:underline">&lt; Start a new registration</router-link></p>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import api from "@/api";
import LoaderComponent from '@/components/LoaderComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'RegistrationCancelledView',
  components: {
    LoaderComponent,
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      loaded: false,
      sessionId: null,
    }
  },
  created () {
    this.sessionId = this.$route.query.session_id
    Promise.all([
      this.loadOrder()
    ]).then(() => {
      this.loaded = true
    })
  },
  methods: {
    loadOrder () {
      return api.post('orders/payment-cancelled', {
        session_id: this.sessionId
      })
    }
  }
}
</script>
