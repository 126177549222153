<template>
  <div class="home font-body text-gray-700">
    <header-component />
    <registration-intro />
    <registration-form />
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import RegistrationIntro from '@/components/registration/RegistrationIntro.vue'
import RegistrationForm from '@/components/registration/RegistrationForm.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    RegistrationIntro,
    RegistrationForm,
    FooterComponent
  }
}
</script>
