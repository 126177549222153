<template>
   <div class="container max-w-screen-lg flex flex-wrap px-6 mt-10 mb-6">
     <h2 class="w-full text-xl mb-2 font-bold font-heading">Register with ABBE</h2>
     <div class="w-full lg:w-2/3 pr-8 text-sm leading-loose">
      <p class="mb-6">
        In order to obtain your qualification, you must register with the accredited exam body 'ABBE', who will
        provide you with a unique learner number, specific to you, which will then allow you to book and take the
        relevant exam.
      </p>
      <p class="mb-6">
        The cost of registration with ABBE and the exam for the Certificate is <strong>£195 + VAT</strong><br />
        The cost of registration with ABBE and the exam for the Diploma is <strong>£215 + VAT</strong>
      </p>
      <p class="mb-6">
        Once PropQaul receives your 'Unique Learner Number' wel will be able to book the exam/exams on your behalf
        with EDExams. Each exam is allocated a 2 hour timeslot to complete the exam.
      </p>
      <p class="mb-6">
        The available timeslots are Monday to Friday:<br />
        9:00am, 9:30am, 10:00am, 10:30am, 11:00am, 11:30am,<br />
        12:00pm, 12:30pm, 1:00pm, 1:30pm, 2:00pm
      </p>
      <p class="mb-6">
        You will receive an email from PropQual containing your Unique Learner number along with the available
        time slots as above, please reply to the email with your preferred date and time to sit the exam and we
        will book the exam on your behalf. Following this you will receive an email directly from EDExams
        confirming your booking and explaining the exam process and what steps you must take to successfully sit
        the exam.
      </p>
      <p class="mb-6">
        You will receive notification of your successful registration and your 'Unique Learner Number' within 14
        days of payment.
      </p>
     </div>
     <div class="w-full lg:w-1/3">
       <ul>
         <li class="mb-6">
           <img src="@/assets/images/logo-abbe.png" alt="ABBE - Quality Accredited" />
         </li>
         <li>
           <img src="@/assets/images/logo-ed-exams.png" alt="EDExams" />
         </li>
       </ul>
     </div>
   </div>
</template>

<script>
export default {
  computed: {}
}
</script>
